@import '../../variables.scss';

.products {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    @media screen and (max-width: $breakpoint-small) {
        padding: 16px;
        flex-direction: column-reverse;
    }
    .image {
        width: 50%;
        @media screen and (max-width: $breakpoint-small) {
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
    .textContainer {
        width: 50%;
        padding: 16px;
        box-sizing: border-box;
        @media screen and (max-width: $breakpoint-small) {
            width: 100%;
        }
        .sectionTitle {
            font-size: 28px;
            font-weight: 600;
        }
        .content {
            margin-top: 16px;
            .clickNote {
                margin-top: 16px;
            }
            .productLinks {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-top: 16px;
                @media screen and (max-width: $breakpoint-large) {
                    gap: 12px;
                }
                .linkContainer {
                    width: 70%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    cursor: pointer;
                    @media screen and (max-width: $breakpoint-large) {
                        width: 100%;
                        align-items: center;
                    }
                    &:hover {
                        .arrowContainer {
                            svg {
                                display: flex;
                            }
                        }
                    }
                    .iconContainer {
                        width: 64px;
                        height: 64px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: $breakpoint-large) {
                            width: 50px;
                            height: 50px;
                        }
                        &.book {
                            background-color: #e4e3fe;
                        }
                        &.register {
                            background-color: #f8f0e2;
                        }
                        &.diary {
                            background-color: #e2f5f4;
                        }
                        svg {
                            width: 36px;
                            height: 36px;
                            @media screen and (max-width: $breakpoint-large) {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                    .text {
                        width: calc(100% - 94px);
                        height: 64px;
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        margin-left: 12px;
                        .title {
                            font-size: 18px;
                            font-weight: 600;
                            color: #222222;
                            text-decoration: underline;
                            text-underline-offset: 2px;
                            @media screen and (max-width: $breakpoint-large) {
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        .subTitle {
                            font-size: 14px;
                            font-weight: 400;
                            color: #757575;
                            margin-top: 4px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            @media screen and (max-width: $breakpoint-large) {
                                margin-top: 2px;
                            }
                        }
                    }
                    .arrowContainer {
                        width: 30px;
                        height: 64px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}