@import '../../variables.scss';

.contactUs {
    width: 100%;
    display: flex;
    padding: 40px;
    box-sizing: border-box;
    @media screen and (max-width: $breakpoint-small) {
        flex-direction: column-reverse;
        padding: 20px;
    }
    .mapContainer {
        width: 50%;
        @media screen and (max-width: $breakpoint-small) {
            width: auto;
        }
        iframe {
            width: 90%;
            @media screen and (max-width: $breakpoint-small) {
                width: auto;
            }
        }
    }
    .addressContainer {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 60px;
        padding-top: 16px;
        box-sizing: border-box;
        @media screen and (max-width: $breakpoint-small) {
            width: auto;
            padding: 16px;
        }
        .title {
            font-size: 28px;
            font-weight: 600;
        }
        .address {
            font-size: 16px;
            color: black;
            text-align: left;
            display: flex;
            flex-direction: row;
            line-height: 1.5;
            .icon {
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            .text {
                margin-left: 8px;
            }
        }
    }
}