@import '../../../variables.scss';

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    text-align: initial;
    z-index: 9999;
    .container {
        padding: 24px;
        .closeBtn {
            display: flex;
            justify-content: flex-end;
            svg {
                width: 24px;
                height: 24px;
            }
        }
        .title {
            .logo {
                width: 64px;
                height: 64px;
            }
        }
    }
}